import { MapContainer, Marker, Popup, TileLayer, Tooltip } from "react-leaflet";
import { LatLngExpression } from "leaflet";
import {
  MapWindow,
  PopupContainer,
  PopupHeading,
  PopupTextGroup,
  PopupTitle,
} from "./Map.style";
import { DeviceMarker } from "../../interfaces/mapMarkers/DeviceMarker";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from "leaflet";
import { sensorValueWithUnit } from "../../utils/dashboardUtils";
import React from "react";
import {
  faCheckCircle,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface MapProps {
  position: LatLngExpression;
  markers?: DeviceMarker[];
}

const LocationDevicesMap = ({ position, markers }: MapProps) => {
  /* Objects */
  const markerIcon = new Icon({
    iconUrl: markerIconPng,
    iconSize: [15, 22],
    iconAnchor: [7.5, 12],
  });

  const formatSensorValue = (sensorValue: number): number => {
    return Number(sensorValue.toFixed(2));
  };

  const renderDeviceStatusIndicator = (status: boolean) => {
    return (
      <FontAwesomeIcon
        icon={status ? faCheckCircle : faCircleXmark}
        color={status ? "#80D39B" : "#D64550"}
      />
    );
  };

  const renderSWRMSensorMarkers = (marker: DeviceMarker) => {
    return (
      <PopupTextGroup>
        {Object.entries(marker.DeviceSensorData.SWRMSensorData).map(
          ([deviceId, sensorData]: [string, any]) => {
            const isPrimaryDeviceData = deviceId === marker.Device.Id;
            const isChildDeviceData = marker.Device.ChildDevices?.some(
              (childDevice) => childDevice.Id === deviceId,
            );

            if (isPrimaryDeviceData || isChildDeviceData) {
              return (
                <React.Fragment key={deviceId}>
                  {Object.entries(sensorData).map(
                    ([sensorType, sensor]: [string, any]) => (
                      <span key={sensorType}>
                        <PopupHeading>{sensorType}:&nbsp;</PopupHeading>
                        {sensorValueWithUnit(
                          sensorType,
                          formatSensorValue(Number(sensor.Value)),
                        )}
                      </span>
                    ),
                  )}
                </React.Fragment>
              );
            }
            return null;
          },
        )}
      </PopupTextGroup>
    );
  };

  return (
    <MapWindow>
      <MapContainer
        key={position?.toString()}
        center={position}
        zoom={13}
        style={{ height: "100%", width: "100%", borderRadius: "6px" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {markers &&
          markers.length > 0 &&
          markers.map((marker) => {
            // Do not put markers if GPS coordinates does not exist.
            if (!marker?.Device?.GPSLatitude || !marker?.Device?.GPSLongitude) {
              return <></>;
            }

            let markerPosition: LatLngExpression = [
              marker.Device.GPSLatitude,
              marker.Device.GPSLongitude,
            ];

            return (
              <Marker position={markerPosition} icon={markerIcon}>
                <Popup>
                  <PopupContainer>
                    <PopupTitle>{marker.Device.Name}</PopupTitle>

                    <PopupTextGroup>
                      <span>
                        <PopupHeading>Longitude:&nbsp;</PopupHeading>
                        {marker.Device.GPSLongitude}
                      </span>
                      <span>
                        <PopupHeading>Latitude:&nbsp;</PopupHeading>
                        {marker.Device.GPSLatitude}
                      </span>
                    </PopupTextGroup>
                    {/* temporary hide online */}
                    {/* <PopupTextGroup>
                      <span>
                        <PopupHeading>Status:&nbsp;</PopupHeading>
                        {marker.Device.ConnectionStatus ? "Online" : "Offline"}
                      </span>
                      <span>
                        <PopupHeading>Firmware:&nbsp;</PopupHeading>
                        {marker.Device.UpToDate ? "Up to date" : "Out of date"}
                      </span>
                    </PopupTextGroup> */}

                    {/* SWRM Sensor Data */}
                    {marker.DeviceSensorData.SWRMSensorData &&
                      renderSWRMSensorMarkers(marker)}
                  </PopupContainer>
                </Popup>

                <Tooltip direction="top" offset={[0, 0]} opacity={1} permanent>
                  {marker.Device.Name}&nbsp;
                  {renderDeviceStatusIndicator(marker.Device.ConnectionStatus)}
                </Tooltip>
              </Marker>
            );
          })}
      </MapContainer>
    </MapWindow>
  );
};

export default LocationDevicesMap;
