import { Form } from "formik";
import styled from "styled-components";
import { UploadProps } from "./Upload";

export const UploadWrapper = styled.div<Pick<UploadProps, "width" | "height">>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => (width ? width : "auto")};
  height: ${({ height }) => (height ? height : "auto")};
  gap: 10px;
`;

export const EditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 30px 0;

  .tox .tox-editor-container {
    border: ${(props) => props.theme.border["primary-sm"]};
    border-radius: 5px;
  }

  .tox
    .tox-toolbar-overlord
    .tox-toolbar:not(.tox-toolbar--scrolling):first-child,
  .tox .tox-toolbar-overlord .tox-toolbar__primary {
    border-bottom: ${(props) => props.theme.border["primary-sm"]};
  }

  .tox:not([dir="rtl"]) .tox-toolbar__group:not(:last-of-type) {
    border-right: ${(props) => props.theme.border["primary-sm"]};
  }

  .tox .tox-toolbar,
  .tox .tox-toolbar__overflow,
  .tox .tox-toolbar__primary {
    background-color: #020a01 !important;
  }
`;

export const ErrorText = styled.div`
  color: #d64550;
  font-size: ${(props) => props.theme.fontSizes["md"]};
  font-weight: 400;
`;
interface StyledFormikFormProps {
  width?: string;
}

export const StyledFormikForm = styled(Form)<StyledFormikFormProps>`
  width: ${(props) => props.width || "inherit"};
`;
