import React, { useState } from "react";
import { DeviceManagerMain } from "../../../device-manager-elements/deviceManagerMain";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import ToggleButton from "../../../button/toggle-button/ToggleButton";
import { DeviceManagerElement } from "../../../device-manager-elements/deviceManagerElement";
import { SensorBlock } from "./Sensors.style";
import sensorService from "../../../../services/device/deviceSensors.service";
import { Sensor } from "../../../../interfaces/device/Sensor";
import { updateSensorsInDevice } from "../../../../store/slices/devices/devicesSlice";
import { Typography } from "../../../typrography/Typography";
import { SwrmDevices } from "../../../../interfaces/device/SwrmDevice";
import { DefaultThemeBorder } from "../../../../app/Theme";
import CustomModal from "../../../modals/CustomModal";
import HelpIcon from "../../../icons/HelpButtonIcon";

export const Sensors = () => {
  const dispatch = useAppDispatch();
  const device = useAppSelector((state) => state.devices);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);
  const selectedDevice = device.selectedDevice;
  const sensors = selectedDevice.Sensors;
  const childDevice = selectedDevice.ChildDevices;
  const updateSensorStatus = async (
    sensor: Sensor,
    active: boolean,
    revert: () => void,
  ) => {
    const updatedSensor = { ...sensor, Active: active };
    const response = await sensorService.patchSensor(updatedSensor, dispatch);

    if (response !== null) {
      dispatch(
        updateSensorsInDevice({
          sensor: response.Result,
          masterDeviceId: selectedDevice.Id || "",
        }),
      );
    } else {
      revert();
    }
  };
  const handleHelpOpen = () => {
    setIsHelpModalOpen(true);
  };
  const onClose = () => {
    setIsHelpModalOpen(false);
  };
  return (
    <DeviceManagerMain data={[]}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          {sensors &&
            !sensors?.length &&
            childDevice &&
            (!childDevice.length ||
              childDevice.every((d: SwrmDevices) => !d.Sensors.length)) && (
              <Typography color="red-100" variant="lg">
                No sensors connected
              </Typography>
            )}
          {sensors && sensors?.length > 0 && (
            <Typography>{selectedDevice.Name}</Typography>
          )}
        </div>
        <HelpIcon size="32px" handleOnClick={handleHelpOpen} />
      </div>
      <SensorBlock>
        {!!sensors &&
          !!sensors?.length &&
          sensors?.map((s: Sensor, index: number) => (
            <div
              key={s.Id}
              style={{
                paddingBottom: "1.5rem",
                border: DefaultThemeBorder["border-sm-white"],
                borderRadius: "5px",
              }}
            >
              <DeviceManagerElement
                config={{
                  header: `Sensor ${index + 1}`,
                  index: s.Id.toString(),
                  mainLabel: (
                    <ToggleButton
                      id={s.Id.toString()}
                      isActive={s.Active}
                      onToggle={(status, revert) =>
                        updateSensorStatus(s, status, revert)
                      }
                    />
                  ),
                  helperLabel: s.Name,
                }}
              />
            </div>
          ))}
      </SensorBlock>
      {!!childDevice &&
        !!childDevice.length &&
        childDevice.map(
          (d: SwrmDevices, index: number) =>
            !!d.Sensors &&
            !!d.Sensors.length && (
              <>
                <Typography>{d.Name}</Typography>
                <SensorBlock>
                  {d.Sensors && d.Sensors?.length > 0 ? (
                    d.Sensors?.map((s: Sensor, index: number) => (
                      <div
                        key={s.Id}
                        style={{
                          paddingBottom: "1.5rem",
                          border: DefaultThemeBorder["border-sm-white"],
                          borderRadius: "5px",
                        }}
                      >
                        <DeviceManagerElement
                          config={{
                            header: `Sensor ${index + 1}`,
                            index: s.Id.toString(),
                            mainLabel: (
                              <ToggleButton
                                id={s.Id.toString()}
                                isActive={s.Active}
                                onToggle={(status, revert) =>
                                  updateSensorStatus(s, status, revert)
                                }
                              />
                            ),
                            helperLabel: s.Name,
                          }}
                        />
                      </div>
                    ))
                  ) : (
                    <Typography color="red-100" variant="lg">
                      No sensors connected
                    </Typography>
                  )}
                </SensorBlock>
              </>
            ),
        )}
      <CustomModal
        isOpen={isHelpModalOpen}
        onClose={onClose}
        padding="1rem 0"
        width="50%"
        height="auto"
        header={
          <Typography
            variant="lg"
            direction="flex-start"
            style={{ padding: "0 0.5rem", fontWeight: "700" }}
          >
            Sensors
          </Typography>
        }
      >
        <div>
          <Typography variant="lg" color="primary" padding={"0.5rem"}>
            This is the sensors tab
          </Typography>
          <Typography variant="md" padding={"0.5rem"}>
            Every sensor that is connected to the device is shown here.
          </Typography>
          <Typography variant="md" padding={"0.5rem"}>
            The sensor can be toggled on or off. When the sensor is toggled off
            no data will be displayed from that sensor.
          </Typography>
        </div>
      </CustomModal>
    </DeviceManagerMain>
  );
};
