import Select, { MenuPlacement } from "react-select";
import {
  ErrorMsg,
  ErrorMsgWrapper,
  FilterDropdownWrapper,
  InputWrapper,
  filterDropdownStyle,
  filterDropdownStyleFull,
} from "./FilterDropdownWithLabel.style";
import { useEffect } from "react";
import { FormControl } from "../../../form-field/form-control/FormControl";
import { FormLabel } from "../../../form-field/form-label/FormLabel";
import { FilterDropdown } from "../FilterDropdown";
import { useField } from "formik";

export interface FilterOption {
  label: string;
  value: string | number;
}
export interface FilterDropdownWithLabelProps {
  defaultValue?: FilterOption | undefined;
  value?: FilterOption | null;
  options: FilterOption[] | undefined;
  title: string;
  name: string;
  isMulti?: boolean;
  menuPlacement?: MenuPlacement;
  isFullSize?: boolean;
  margin?: string;
  padding?: string;
  fontSize?: string;
  onChange?: ({ label, value }: FilterOption) => void;
  hasCustomErrorMessage: boolean;
  hasErrorMessage?: boolean;
  wrapperPadding?: string;
}

export const FilterDropdownWithLabel = ({
  defaultValue,
  value,
  options,
  title,
  menuPlacement,
  isFullSize,
  margin,
  padding,
  fontSize,
  name,
  onChange,
  hasCustomErrorMessage,
  hasErrorMessage = false,
  wrapperPadding,
}: FilterDropdownWithLabelProps) => {
  const [field, meta] = useField(name);
  const handleOptionClick = (option: FilterOption | null) => {
    const selectedOption: FilterOption = {
      label: option?.label!,
      value: option?.value!,
    };

    if (onChange) onChange(selectedOption);
  };

  const validateDefaultValue =
    defaultValue == null ||
    defaultValue.label == undefined ||
    defaultValue.label == ""
      ? undefined
      : defaultValue;
  return (
    <InputWrapper padding={wrapperPadding || padding} fontSize={fontSize}>
      <FormControl>
        <FormLabel
          htmlFor={name}
          fontSize={fontSize}
          fontWeight="700"
          style={{ color: "white" }}
          padding={padding}
          margin={margin || "0 0 0.5rem"}
        >
          {name}
        </FormLabel>
        <div style={{ width: "100%" }}>
          <FilterDropdown
            options={options}
            isFullSize={isFullSize}
            title={title}
            defaultValue={validateDefaultValue}
            onChange={(option: FilterOption | null) =>
              handleOptionClick(option)
            }
            {...(value === null && { value: null })}
          />
          {hasErrorMessage && (
            <ErrorMsgWrapper fontSize={fontSize}>
              {meta.touched && meta.error && !hasCustomErrorMessage && (
                <ErrorMsg style={{}}>{meta.error}</ErrorMsg>
              )}
            </ErrorMsgWrapper>
          )}
        </div>
      </FormControl>
    </InputWrapper>
  );
};
