import * as Yup from "yup";
import { Step } from "../../../../interfaces/device/Step";
import {
  CreateRoutinePayload,
  Routine,
} from "../../../../interfaces/device/Routine";
import { Formik, FormikHelpers } from "formik";
import { StyledFormikForm } from "../../ai-model-manager/Upload.style";
import {
  RelayAddRoutineWrapper,
  RoutineErrorMessage,
  RoutineStepActionButton,
  RoutineStepsFormsWrapper,
} from "./RelayRoutine.style";
import { RelayAddRoutineBoxHeader } from "./RelayBox.style";
import { CheckboxWrapper } from "../../../tables/table-button/TableButton.style";
import { Typography } from "../../../typrography/Typography";
import Checkbox from "../../../checkbox/Checkbox";
import { FlexContainer } from "../../../flex-container/FlexContainer";
import Input from "../../../input/Input";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ScaledButtonWrapper } from "../../../modals/CustomModal.style";
import React, { useEffect, useRef, useState } from "react";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import RelayRoutineForm from "./RelayRoutineForm";
import { FilterOption } from "../../../dropdown/filter-dropdown/FilterDropdown";
import { RelayRoutineFormValues } from "./RelayRoutineSetup";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../notification/Notification";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { ResponseObject } from "../../../../interfaces/response/Response";
import routineService, {
  getRoutines,
  getRoutineStepRelays,
  getRoutineSteps,
} from "../../../../services/device/deviceRoutines.service";
import { AppDispatch } from "../../../../store/store";
import { uid } from "uid";

interface Props {
  routine: Routine;
  steps: Step[];
  relayOptions: FilterOption[];
  onCancel: () => void;
}

interface RelayRoutineFormsProps {
  key: string;
  component: JSX.Element;
}

interface FormValues {
  interval: number | undefined | null;
}

const validationSchema = Yup.object({
  interval: Yup.number().required("Value is required").min(0, "Value invalid"),
});

const RelayRoutineEdit = ({
  routine,
  steps,
  relayOptions,
  onCancel,
}: Props) => {
  const dispatch: AppDispatch = useAppDispatch();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [loopIndefinitelyStatus, setLoopIndefinitelyStatus] = useState(
    routine.RepeatIndefinite,
  );
  const [routineFormFieldValues, setRoutineFormFieldValues] = useState<
    RelayRoutineFormValues[]
  >([]);
  const scrollToRef = useRef<HTMLDivElement>(null);
  const devices = useAppSelector((state) => state.devices);
  const selectedDevice = devices.selectedDevice;

  const defaultValues: FormValues = {
    interval: routine.Interval,
  };

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    // Check if Relays were passed to all steps
    const formsWithNoRelays: RelayRoutineFormValues[] =
      routineFormFieldValues.filter((form) => {
        return form.Turn == 1 && form.Relays.length == 0;
      });

    const firstFormHasNoRelays: boolean =
      routineFormFieldValues[0].Relays.length == 0;

    if (firstFormHasNoRelays) {
      return OpenErrorNotification("First step must have selected relays");
    }

    if (formsWithNoRelays.length !== 0) {
      return OpenErrorNotification("Please select relays for the routine");
    }

    const payload: CreateRoutinePayload = {
      DeviceId: selectedDevice.Id,
      RepeatIndefinitely: loopIndefinitelyStatus,
      Interval: Number(values.interval),
      RoutineSteps: routineFormFieldValues,
    };

    const response = await routineService.alterRoutine(payload, dispatch);

    if (response !== null) {
      dispatch(getRoutines(selectedDevice.Id));
      dispatch(getRoutineSteps(selectedDevice.Id));
      dispatch(getRoutineStepRelays(selectedDevice.Id));
      onCancel();
    }
  };

  const handleRoutineStepChanges = (
    index: number,
    fieldName: string,
    value: number | number[],
  ) => {
    setRoutineFormFieldValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = {
        ...newValues[index],
        [fieldName]: value,
      };
      return newValues;
    });
  };

  const handleAddRoutine = () => {
    return null;
  };

  const updateStates = async (incrementStates: boolean) => {
    setCurrentIndex((prevCurrentIndex) =>
      incrementStates ? prevCurrentIndex + 1 : prevCurrentIndex - 1,
    );
  };

  const handleAddNewStep = async () => {
    console.log("Current Index", currentIndex);
    setRoutineStepForms([
      ...routineStepForms,
      {
        key: uid(),
        component: (
          <RelayRoutineForm
            index={currentIndex}
            stepNumber={currentIndex + 1}
            relayOptions={relayOptions}
            onEditRoutine={handleRoutineStepChanges}
            onSaveRoutine={handleAddRoutine}
          />
        ),
      },
    ]);

    await updateStates(true);
  };

  const handleRemoveStep = () => {
    const copiedRoutineStepForms: RelayRoutineFormsProps[] = [
      ...routineStepForms,
    ];
    const copiedRoutineFormValues: RelayRoutineFormValues[] = [
      ...routineFormFieldValues,
    ];
    copiedRoutineStepForms.pop();
    copiedRoutineFormValues.pop();
    setRoutineStepForms(copiedRoutineStepForms);
    setRoutineFormFieldValues(copiedRoutineFormValues);
    updateStates(false);
  };

  const seedRoutineSteps = (): RelayRoutineFormsProps[] => {
    return steps.map((step: Step, index: number) => {
      updateStates(true);

      handleRoutineStepChanges(
        index,
        "Relays",
        step.RelayStatus ? selectedDevice.StepRelayIds[index] : [],
      );

      return {
        key: uid(),
        component: (
          <RelayRoutineForm
            index={index}
            stepNumber={index + 1}
            relayOptions={relayOptions}
            onEditRoutine={handleRoutineStepChanges}
            onSaveRoutine={handleAddRoutine}
            step={step}
          />
        ),
      };
    });
  };

  const [routineStepForms, setRoutineStepForms] =
    useState<RelayRoutineFormsProps[]>(seedRoutineSteps);

  useEffect(() => {
    scrollToRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [currentIndex]);

  return (
    <>
      {/* Action buttons */}
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, handleChange, errors }: any) => (
          <StyledFormikForm id="update-routine-form">
            <RelayAddRoutineWrapper>
              <RelayAddRoutineBoxHeader>
                <CheckboxWrapper gap="0.5rem">
                  <Typography variant="md">Repeat</Typography>
                  <Checkbox
                    isChecked={loopIndefinitelyStatus}
                    onChange={() => {
                      setLoopIndefinitelyStatus((currentStatus) => {
                        if (currentStatus) {
                          setFieldValue("interval", 5);
                        } else {
                          setFieldValue("interval", 0);
                        }

                        return !currentStatus;
                      });
                    }}
                    width="1rem"
                    height="1rem"
                  />
                </CheckboxWrapper>
                <FlexContainer gap="1rem" alignItems="center">
                  <Typography variant="md" color="grey-500">
                    Times
                  </Typography>

                  <div style={{ position: "relative" }}>
                    <Input
                      type="number"
                      name="interval"
                      width="4rem"
                      height="1.7rem"
                      padding="0"
                      margin="0"
                      value={defaultValues.interval?.toString()}
                      onChange={handleChange}
                      alignCenter
                      disabled={loopIndefinitelyStatus}
                      hasErrorMessage={false}
                    />

                    <RoutineErrorMessage right="-75%">
                      {errors.interval}
                    </RoutineErrorMessage>
                  </div>
                </FlexContainer>
              </RelayAddRoutineBoxHeader>

              <RoutineStepsFormsWrapper>
                {routineStepForms.map(({ key, component }) => (
                  <React.Fragment key={key}>{component}</React.Fragment>
                ))}
              </RoutineStepsFormsWrapper>

              <FlexContainer gap="1.3rem" justifyContent="start">
                <Tooltip id="add-step-tooltip" />
                <RoutineStepActionButton
                  data-tooltip-id="add-step-tooltip"
                  data-tooltip-content="Add Step"
                  data-tooltip-place="top"
                  type="button"
                  onClick={handleAddNewStep}
                >
                  <FontAwesomeIcon
                    size="lg"
                    icon={faPlus}
                    data-tooltip-id="add-step-tooltip"
                    data-tooltip-content="Add Step"
                    data-tooltip-place="top"
                  />

                  <Typography variant="xs" color="grey-500">
                    Add new step
                  </Typography>
                </RoutineStepActionButton>

                {routineStepForms.length > 1 && (
                  <>
                    <Tooltip id="remove-step-tooltip" />
                    <RoutineStepActionButton
                      data-tooltip-id="remove-step-tooltip"
                      data-tooltip-content="Remove Step"
                      data-tooltip-place="top"
                      type="button"
                      onClick={handleRemoveStep}
                    >
                      <FontAwesomeIcon
                        size="lg"
                        icon={faTrash}
                        data-tooltip-id="remove-step-tooltip"
                        data-tooltip-content="Remove Step"
                        data-tooltip-place="top"
                      />
                      <Typography variant="xs" color="grey-500">
                        Delete last step
                      </Typography>
                    </RoutineStepActionButton>
                  </>
                )}
              </FlexContainer>

              <FlexContainer
                gap="1rem"
                justifyContent="center"
                alignItems="center"
              >
                <ScaledButtonWrapper buttonPadding={"10px"}>
                  <button type="submit" form="update-routine-form">
                    Update
                  </button>
                  <button type="button" className="danger" onClick={onCancel}>
                    Cancel
                  </button>
                </ScaledButtonWrapper>
              </FlexContainer>
            </RelayAddRoutineWrapper>
          </StyledFormikForm>
        )}
      </Formik>
      {/* Scroll To Anchor */}
      <div ref={scrollToRef}></div>
    </>
  );
};

export default RelayRoutineEdit;
