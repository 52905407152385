import { useEffect, useState } from "react";
import { DeviceManagerMain } from "../../../device-manager-elements/deviceManagerMain";
import DeviceModelBox from "../../../device-model-box/DeviceModelBox";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getAIModelsDropdown } from "../../../../store/slices/dropdownOptions/dropdownListSlice";
import AIModelService, {
  getAllAIModels,
} from "../../../../services/aiModels/aimodel.service";
import CustomModal from "../../../modals/CustomModal";
import { Typography } from "../../../typrography/Typography";
import { ScaledButtonWrapper } from "../../../modals/CustomModal.style";
import { Formik } from "formik";
import * as Yup from "yup";
import { StyledFormikForm } from "../../ai-model-manager/Upload.style";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
  OpenWarningNotification,
} from "../../../notification/Notification";
import deviceModelService from "../../../../services/device/deviceModel.service";
import { DeviceModel } from "../../../../interfaces/device/DeviceModel";
import { updateDeviceModelInDevice } from "../../../../store/slices/devices/devicesSlice";
import { ResponseObject } from "../../../../interfaces/response/Response";
import { AIModel } from "../../../../interfaces/aiModel/AIModel";
import {
  FilterDropdown,
  FilterOption,
} from "../../../dropdown/filter-dropdown/FilterDropdown";
import Input from "../../../input/Input";
import {
  MessagePopupSetup,
  showPopupMessage,
} from "../../../../store/slices/messagePopups/messagePopupSlice";

interface FormValues {
  limit: number;
}

export const AIModelControl = () => {
  const dispatch = useAppDispatch();
  const device = useAppSelector((state) => state.devices);
  const aiModels = useAppSelector((state) => state.aiModels);
  const aiModelDropdown = useAppSelector(getAIModelsDropdown);
  const selectedDevice = device.selectedDevice;
  const selectedDeviceAIModel = selectedDevice?.DeviceModel;
  const selectedModel: FilterOption = {
    label: selectedDeviceAIModel?.AIModel?.Name || "",
    value: selectedDeviceAIModel?.AIModelId || 0,
  };
  const [selectedModelId, setSelectedModelId] = useState<number>(
    selectedDeviceAIModel?.AIModelId! || 0,
  );
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const initialValues: FormValues = {
    limit: selectedDevice.DeviceModel?.Limit! || 2,
  };

  const validationSchema = Yup.object({
    limit: Yup.number()
      .required("Limit required")
      .positive("Invalid limit")
      .min(2, "Invalid Limit")
      .max(1000, "Invalid Limit"),
  });

  // Fetch all locations, devices, and AI models on initial load of the page
  useEffect(() => {
    console.log(selectedDeviceAIModel);
    dispatch(getAllAIModels());
  }, []);

  const handleDropdownChange = (option: number) => {
    setSelectedModelId(option);
  };

  const handleSelectAIModel = (index: number) => {
    setIsOpenModal(true);
  };

  const onClose = () => {
    console.log("onClose");
    setIsOpenModal(false);
  };

  const cardHeader = () => {
    return (
      <Typography
        variant="lg"
        direction="flex-start"
        style={{ padding: "0 20px", fontWeight: "700" }}
      >
        {selectedDeviceAIModel ? "Select" : "Update"} AI Model
      </Typography>
    );
  };

  const cardFooter = () => {
    return (
      <ScaledButtonWrapper buttonPadding={"10px"}>
        <button type="submit" form="ai-model-selection">
          Save
        </button>
      </ScaledButtonWrapper>
    );
  };

  const handleSubmit = async (values: FormValues, { setSubmitting }: any) => {
    try {
      // If a model already exists on the device, and only the limit is updated
      if (selectedDeviceAIModel && selectedModelId !== 0) {
        if (selectedDeviceAIModel.AIModelId === selectedModelId) {
          const sendLimitResponse =
            await deviceModelService.sendDetectionUploadLimit(
              selectedDevice.Id,
              values.limit,
              dispatch,
            );

          if (sendLimitResponse !== null) {
            return onSuccess(sendLimitResponse.Result);
          }
        }
      }

      // If user added/updated the model, we upload new model to the device
      const oldDeviceModelId = selectedDeviceAIModel?.Id || 0;
      const model = aiModels?.data.find(
        (x: AIModel) => x.Id === selectedModelId,
      );
      console.log("Here before first if");
      if (selectedModelId) {
        console.log("Here inside first if");
        const payload: DeviceModel = {
          Id: oldDeviceModelId,
          DeviceId: selectedDevice.Id || "",
          AIModelId: selectedModelId,
          Limit: values.limit,
        };

        // Get AI Model Download URL from S3
        const modelURLResponse: ResponseObject<string> | null =
          await AIModelService.getAIModelDownloadURL(
            payload.AIModelId,
            dispatch,
          );

        console.log("Model URL Response:", modelURLResponse);
        if (modelURLResponse) {
          payload.AIModelName = model?.Name!;
          payload.DownloadURL = modelURLResponse.Result;
          payload.ModelHashCode = model?.ModelHashCode;

          // Update database
          if (oldDeviceModelId) {
            const response: ResponseObject<DeviceModel> | null =
              await deviceModelService.patchDeviceModel(payload, dispatch);
            if (response !== null) {
              onSuccess(response.Result);
            }
          } else {
            const response: ResponseObject<DeviceModel> | null =
              await deviceModelService.postDeviceModel(payload, dispatch);

            if (response !== null) {
              onSuccess(response.Result);
            }
          }
        }
      } else {
        const messagePopup: MessagePopupSetup = {
          code: null,
          message: "Please select an AI Model.",
        };

        console.log("In else statement");
        dispatch(showPopupMessage(messagePopup));
      }
    } catch (error) {
      console.error("handelSubmit Error:", error);
    }
  };

  const onSuccess = (data: DeviceModel) => {
    if (data) {
      dispatch(updateDeviceModelInDevice(data));
      onClose();
    }
  };

  return (
    <>
      <DeviceManagerMain data={[]}>
        {selectedDevice?.DeviceModel ? (
          <DeviceModelBox
            index={0}
            title={selectedDeviceAIModel?.AIModel?.Name || ""}
            onClick={handleSelectAIModel}
            hideIcon
            helperText={""}
          />
        ) : (
          <DeviceModelBox
            index={0}
            title="Select Model"
            onClick={handleSelectAIModel}
          />
        )}
      </DeviceManagerMain>
      {/* set relation with device */}
      <CustomModal
        isOpen={isOpenModal}
        onClose={onClose}
        padding="1rem 0"
        width="20vw"
        header={cardHeader()}
        footer={cardFooter()}
      >
        <div style={{ padding: "1rem", height: "210px" }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, handleChange, handleBlur, values }: any) => (
              <StyledFormikForm id="ai-model-selection">
                <FilterDropdown
                  defaultValue={selectedModel}
                  title="Select Model"
                  options={aiModelDropdown?.options || []}
                  isFullSize
                  onChange={({ label, value }: FilterOption) => {
                    handleDropdownChange(Number(value));
                  }}
                />

                <div style={{ position: "relative" }}>
                  <Input
                    type="number"
                    name="limit"
                    placeHolder="Min 2 - Max 1000"
                    label={"Upload Limit per hour"}
                    width="100%"
                    margin="0.5rem 0 0.5rem"
                    value={values.limit}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>
              </StyledFormikForm>
            )}
          </Formik>
        </div>
      </CustomModal>
    </>
  );
};
