import React, { Fragment, useEffect, useState } from "react";
import { DeviceManagerMain } from "../../../device-manager-elements/deviceManagerMain";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import ToggleButton from "../../../button/toggle-button/ToggleButton";
import { SensorBlock } from "../sensors/Sensors.style";
import { Typography } from "../../../typrography/Typography";
import { Relay, RelayWithValues } from "../../../../interfaces/device/Relay";
import relayService, {
  syncRelays,
} from "../../../../services/device/deviceRelays.service";
import { updateRelayInDevice } from "../../../../store/slices/devices/devicesSlice";
import RelayBox from "./RelayBox";
import SetupRelayDialog from "./SetupRelayDialog";
import { SwrmDevices } from "../../../../interfaces/device/SwrmDevice";
import { DeviceRelayWrapper, RelayButton } from "./RelayBox.style";
import RelayRoutineDialog from "./RelayRoutineDialog";
import SyncIcon from "../../../icons/SyncIcon";
import RelayRuleDialog from "./RelayRuleDialog";
import HelpIcon from "../../../icons/HelpButtonIcon";
import CustomModal from "../../../modals/CustomModal";

export const Relays = () => {
  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);
  const [isRoutineModalOpen, setIsRoutineModelOpen] = useState<boolean>(false);
  const [isRuleModalOpen, setIsRuleModalOpen] = useState<boolean>(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);
  const [relayForSetup, setRelayForSetup] = useState<RelayWithValues | null>(
    null,
  );
  const dispatch = useAppDispatch();
  const device = useAppSelector((state) => state.devices);
  const selectedDevice = device.selectedDevice;
  const relays = selectedDevice.RelayWithValues;
  const childDevice = selectedDevice.ChildDevices;
  const updateRelayStatus = async (
    relay: Relay,
    value: boolean,
    revert: () => void,
  ) => {
    const response = await relayService.addRelayValue(
      relay.Id,
      value,
      dispatch,
    );

    if (response !== null) {
      dispatch(
        updateRelayInDevice({
          relay: response.Result,
          masterDeviceId: selectedDevice.Id || "",
        }),
      );
    } else {
      revert();
    }
  };

  const handleRelayEdit = (relay: RelayWithValues) => {
    console.log("handleRelayEdit", relay);
    setRelayForSetup(relay);
    setIsModelOpen(true);
  };

  const handleRelaySync = async () => {
    await dispatch(syncRelays(selectedDevice.Id));
  };
  const handleHelpOpen = () => {
    console.log("help button pressed");
    setIsHelpModalOpen(true);
  };

  const onClose = () => {
    setIsModelOpen(false);
    setIsRuleModalOpen(false);
    setIsRoutineModelOpen(false);
    setRelayForSetup(null);
    setIsHelpModalOpen(false);
  };

  return (
    <DeviceManagerMain data={[]}>
      {relays &&
      !relays?.length &&
      childDevice &&
      (!childDevice.length ||
        childDevice.every((d: SwrmDevices) => !d.RelayWithValues.length)) ? (
        <>
          <Typography color="red-100" variant="lg">
            No relays connected
          </Typography>
          <DeviceRelayWrapper>
            <HelpIcon size="32px" handleOnClick={handleHelpOpen} />
          </DeviceRelayWrapper>
        </>
      ) : null}
      {relays && relays?.length > 0 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <DeviceRelayWrapper>
            <Typography>{selectedDevice.Name}</Typography>
            <RelayButton onClick={() => setIsRuleModalOpen(true)}>
              Create Rule
            </RelayButton>
            <RelayButton onClick={() => setIsRoutineModelOpen(true)}>
              Create Timer
            </RelayButton>
            {/* Commented out for the time being. Will re-implement when starting the task */}
            {/* <RelayButton onClick={() => null}>Create Schedules</RelayButton> */}
          </DeviceRelayWrapper>
          <DeviceRelayWrapper>
            <SyncIcon
              size="32px"
              handleSyncOnClick={handleRelaySync}
              tooltipContent="Sync Relays"
            />
            <HelpIcon size="32px" handleOnClick={handleHelpOpen} />
          </DeviceRelayWrapper>
        </div>
      )}
      <SensorBlock>
        {!!relays &&
          !!relays?.length &&
          relays?.map((r: RelayWithValues, index: number) => (
            <div key={r.Id} style={{}}>
              <RelayBox title={`${r.Name}`} onEdit={() => handleRelayEdit(r)}>
                <ToggleButton
                  id={r.Id.toString()}
                  isActive={r.Active || false}
                  onToggle={(status, revert) =>
                    updateRelayStatus(r, status, revert)
                  }
                />
              </RelayBox>
            </div>
          ))}
      </SensorBlock>
      {!!childDevice &&
        !!childDevice.length &&
        childDevice.map((d, index) =>
          !!d.RelayWithValues && !!d.RelayWithValues.length ? (
            <Fragment key={d.Id}>
              <Typography>{d.Name}</Typography>
              <SensorBlock>
                {!!d.RelayWithValues &&
                  d.RelayWithValues &&
                  !!d.RelayWithValues &&
                  d.RelayWithValues?.length &&
                  d.RelayWithValues &&
                  d.RelayWithValues?.map(
                    (r: RelayWithValues, index: number) => (
                      <div key={r.Id} style={{}}>
                        <RelayBox
                          title={`${r.Name}`}
                          onEdit={() => handleRelayEdit(r)}
                        >
                          <ToggleButton
                            id={r.Id.toString()}
                            isActive={r.Active || false}
                            onToggle={(status, revert) =>
                              updateRelayStatus(r, status, revert)
                            }
                          />
                        </RelayBox>
                      </div>
                    ),
                  )}
              </SensorBlock>
            </Fragment>
          ) : null,
        )}
      {isModelOpen && relayForSetup && (
        <SetupRelayDialog relay={relayForSetup} onClose={onClose} />
      )}

      {/* Relay Rule, Timer, Routine Buttons */}
      {isRuleModalOpen && (
        <RelayRuleDialog
          selectedDevice={selectedDevice}
          relays={relays}
          onClose={onClose}
        />
      )}
      {isRoutineModalOpen && <RelayRoutineDialog onClose={onClose} />}

      <CustomModal
        isOpen={isHelpModalOpen}
        onClose={onClose}
        padding="1rem 0"
        width="50%"
        height="auto"
        header={
          <Typography
            variant="lg"
            direction="flex-start"
            style={{ padding: "0 20px", fontWeight: "700" }}
          >
            Relays
          </Typography>
        }
      >
        <div>
          <Typography variant="lg" color="primary" padding={"0.5rem"}>
            This is the relays tab
          </Typography>
          <Typography variant="md" padding={"0.5rem"}>
            Every relay that is connected to the device is shown here.
          </Typography>
          <Typography variant="md" padding={"0.5rem"}>
            The relay can be renamed at any point to reflect what device is
            currently plugged into the relay.
          </Typography>
          <Typography variant="md" padding={"0.5rem"}>
            The relay can be toggled on or off. When the relay is toggled it
            will turn on or off any devices currently plugged into the relay.
          </Typography>
          <Typography variant="md" padding={"0.5rem"}>
            Using the 'Create Rule' button rules can be created to turn on or
            off the relay based on sensor values.
          </Typography>
          <Typography variant="md" padding={"0.5rem"}>
            Using the 'Create Timer' button a set of instructions can be created
            to perform events that rely on timing.
          </Typography>
          <Typography variant="md" padding={"0.5rem"}>
            The 'Sync Relay' button updates the state of the dashboard to
            reflect the state of the relays on the device
          </Typography>
        </div>
      </CustomModal>
    </DeviceManagerMain>
  );
};
