import { useEffect, useState } from "react";
import { Typography } from "../../../typrography/Typography";
import CustomModal from "../../../modals/CustomModal";
import { OpenSuccessNotification } from "../../../notification/Notification";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SetupAddRuleTimerHeading } from "./RelayBox.style";
import ConfirmationModal from "../../../modals/confirmation-modal/ConfirmationModal";
import { FilterOption } from "../../../dropdown/filter-dropdown/FilterDropdown";
import RelayRoutineSetup from "./RelayRoutineSetup";
import { Routine } from "../../../../interfaces/device/Routine";
import RelayRoutineItem from "./RelayRoutineItem";
import { AppDispatch } from "../../../../store/store";
import { ResponseObject } from "../../../../interfaces/response/Response";
import routineService, {
  deleteRoutine,
  getRoutines,
  getRoutineStepRelays,
  getRoutineSteps,
} from "../../../../services/device/deviceRoutines.service";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { CustomIconButton } from "../../../button/icon-button/CustomIconButton";
interface Props {
  onClose: () => void;
}

export default function RelayRoutineDialog({ onClose }: Props) {
  const dispatch: AppDispatch = useAppDispatch();
  const devices = useAppSelector((state) => state.devices);
  const selectedDevice = devices.selectedDevice;
  const [routines, setRoutines] = useState<Routine[]>([]);
  const [relayDropdownOptions, setRelayDropdownOptions] = useState<
    FilterOption[]
  >([]);
  const [currentRoutineToDelete, setCurrentRoutineToDelete] =
    useState<number>(0);
  const [confirmMsg, setConfirmMsg] = useState<string>("");
  const [openRoutineForm, setOpenRoutineForm] = useState<boolean>(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const onCancel = () => {
    setOpenRoutineForm(false);
  };

  const handleOpenAddRoutineForm = () => {
    if (routines.length == 0) {
      setOpenRoutineForm(true);
    }
  };

  const handleToggleRoutine = async (
    deviceId: string,
    status: boolean,
    revert: () => void,
  ) => {
    const response = await routineService.updateRoutineStatus(
      deviceId,
      status,
      dispatch,
    );

    if (response !== null) {
      dispatch(getRoutines(selectedDevice.Id));
    } else {
      revert();
    }
  };

  const handleDeleteRoutine = async () => {
    const response = await dispatch(deleteRoutine(selectedDevice.Id));

    if (response !== null) {
      await dispatch(getRoutines(selectedDevice.Id));
      await dispatch(getRoutineSteps(selectedDevice.Id));
      await dispatch(getRoutineStepRelays(selectedDevice.Id));
    }

    handleConfirmModalClose();
  };

  const handleDeleteRoutineClick = (routineId: number) => {
    const msg = `Are you sure you'd like to delete the routine? This cannot be undone.`;
    setConfirmMsg(msg);
    setCurrentRoutineToDelete(routineId);
    setDeleteModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setDeleteModalOpen(false);
    setCurrentRoutineToDelete(0);
  };

  const cardHeader = () => {
    return (
      <Typography
        variant="lg"
        direction="flex-start"
        style={{ padding: "0 20px", fontWeight: "700" }}
      >
        Relay Routines
      </Typography>
    );
  };

  useEffect(() => {
    const relayOptions: FilterOption[] = selectedDevice.RelayWithValues.map(
      (relay) => {
        return { label: relay.Name, value: relay.Id };
      },
    );

    setRelayDropdownOptions([...relayOptions]);
    setRoutines([...selectedDevice.Routines]);
  }, [selectedDevice.RelayWithValues, selectedDevice.Routines]);

  return (
    <CustomModal
      isOpen={true}
      onClose={onClose}
      padding="1rem 0"
      width="60vw"
      height="fit-content"
      header={cardHeader()}
    >
      <div style={{ padding: "0 1rem" }}>
        <SetupAddRuleTimerHeading>
          <Typography color="primary" padding="1rem 0">
            Routines
          </Typography>
          <CustomIconButton onClick={handleOpenAddRoutineForm}>
            <FontAwesomeIcon
              icon={faPlus}
              className={
                openRoutineForm || routines.length > 0 ? "disabled" : ""
              }
            />
          </CustomIconButton>
        </SetupAddRuleTimerHeading>
        {/* add routine form */}
        {openRoutineForm && (
          <RelayRoutineSetup
            relayOptions={relayDropdownOptions}
            onCancel={onCancel}
            selectedDeviceId={selectedDevice.Id}
          />
        )}

        {/* routines */}
        {routines.length > 0 &&
          !openRoutineForm &&
          routines.map((routine) => {
            return (
              <RelayRoutineItem
                key={routine.Id}
                routine={routine}
                relayOptions={relayDropdownOptions}
                onToggleRoutine={handleToggleRoutine}
                onDeleteRoutine={handleDeleteRoutineClick}
              />
            );
          })}
      </div>

      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        onConfirm={handleDeleteRoutine}
        headerTitle={"Delete Routine"}
        confirmMsg={confirmMsg}
        onClose={handleConfirmModalClose}
        modalWidth="30vw"
        minWidth="450px"
      />
    </CustomModal>
  );
}
