import styled from "styled-components";
import { LocationCreateModalProps } from "./location-create-modal/LocationCreateModal";
import { CustomModalProps } from "./CustomModal";

export const ModalWrapper = styled.div<
  Pick<CustomModalProps, "justifyContent" | "alignItems">
>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "center"};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
`;

export const ModalContent = styled.div<
  Pick<
    CustomModalProps,
    "width" | "height" | "padding" | "gap" | "border" | "minWidth" | "minHeight"
  >
>`
  position: relative;
  background-color: #020a01;
  width: ${({ width }) => (width ? width : "48vw")};
  height: ${({ height }) => (height ? height : "auto")};
  max-width: ${({ width }) => (width ? width : "48vw")};
  max-height: ${({ height }) => (height ? height : "fit-Content")};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "auto")};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "auto")};
  padding: ${({ padding }) => (padding ? padding : 0)};
  border-radius: 5px;
  border: ${({ border, theme }) =>
    border ? border : theme.border["primary-md"]};
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : "10px")};

  & > .content {
    overflow: auto;

    /* Customize the scrollbar style */
    ::-webkit-scrollbar {
      width: 5px;
      height: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgb(90, 90, 90); /* Color of the scrollbar thumb */
      border-radius: 5px; /* Roundness of the scrollbar thumb */
    }

    ::-webkit-scrollbar-track {
      background-color: #020a01; /* Color of the scrollbar track */
      border-radius: 5px; /* Roundness of the scrollbar track */
    }
  }
  @media screen and (max-height: ${(props) => props.theme.breakpoints.sm}) {
    max-height: 100vh;
  }
`;

export const DeleteModalContent = styled(ModalContent)<
  Pick<CustomModalProps, "width" | "height" | "padding">
>`
  width: ${({ width }) => (width ? width : "30vw")};
  height: ${({ height }) => (height ? height : "auto")};
  padding: ${({ padding }) => (padding ? padding : "10px 0")};
`;

export const HorizontalLine = styled.hr`
  border: ${(props) => props.theme.border["primary-sm"]};
`;

export const ContentWrapper = styled.div`
  padding: 20px 30px;
`;

export const DeleteContentWrapper = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  padding: 15px 0;
`;

export const FormWrapper = styled.div<
  Pick<LocationCreateModalProps, "formPadding">
>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${({ formPadding }) => (formPadding ? formPadding : "0")};
  position: relative;
`;
export const LocalModalFormWrapper = styled(FormWrapper)`
  flex-wrap: wrap;
  gap: 1rem;
  & > div {
    width: calc(50% - 1rem);
  }
`;
export const BasicModalFormWrapper = styled(FormWrapper)`
  flex-wrap: wrap;
  & > div {
    width: calc(50% - 1rem);
  }

  &.company-options-creation-modal {
    @media (max-width: 900px) {
      flex-direction: column;
      padding: 0 1.25rem;

      & > div {
        width: 100%;
      }
    }
  }
`;
export const CustomGrid = styled.div<{ $customColumns?: string }>`
  display: Grid;
  grid-template-columns: ${(props) =>
    props.$customColumns ? props.$customColumns : "100%"};
`;

export const ScaledButtonWrapper = styled.div<
  Pick<
    LocationCreateModalProps,
    "buttonPadding" | "buttonMargin" | "buttonHeight" | "width"
  >
>`
  width: ${({ width }) => (width ? width : "100%")};
  height: ${({ buttonHeight }) => (buttonHeight ? buttonHeight : "60px")};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ buttonPadding }) => (buttonPadding ? buttonPadding : "0 1rem")};
  margin: ${({ buttonMargin }) => (buttonMargin ? buttonMargin : "0px")};

  & button {
    width: auto;
    min-width: 200px;
    max-width: 100%;
    margin: 10px;
    height: 2.5rem;
    padding: 0 0.5em;
    border-radius: 26px;
    background: ${(props) => props.theme.colors["primary"]};
    color: #111817;
    font-weight: 900;
    font-size: ${(props) => props.theme.fontSizes["sm"]};
    transition: transform 0.2s;
    border: ${(props) => props.theme.border["primary-sm"]};
    cursor: pointer;
  }

  & button:hover {
    transform: scale(1.1);
  }

  & button.danger {
    background: #d64550;
    border: 1px solid #d64550;
  }
`;
